import { FeedScope, RegularPostInput } from '@/__generated__/graphql.ts';
import { gql } from '@/__generated__/index.ts';
import { updateFeedCache } from '@/apollo-cache-utils/update-feed-cache.ts';
import { paths } from '@/routes-utils/paths.ts';
import { useAuthInfo } from '@/routes/_app/use-auth-info.ts';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { ToastAction } from '@synoptic/ui-kit/toast/toast-action.js';
import { useToast } from '@synoptic/ui-kit/toast/toast-provider.js';
import { PostCardFragment } from '../post-card/post-card-fragment.ts';

const ADD_POST = gql(`
  mutation AddPost($input: RegularPostInput!) {
    addPost(input: $input) {
      ...PostCardFragment
    }
  }
`);

export const useAddPost = () => {
  const { id: myId } = useAuthInfo();
  const navigate = useNavigate();
  const toast = useToast();

  const [addPostMutation, result] = useMutation(ADD_POST, {
    onError() {
      toast.error({
        title: 'Failed to make a post',
        subTitle: 'Please try again',
      });
    },
    onCompleted(post) {
      toast.info({
        title: 'Your post was sent',
        action: (
          <ToastAction
            onClick={() => navigate(paths.makePost(post.addPost.id))}
            altText="Go to you profile page to view your new post"
          >
            View
          </ToastAction>
        ),
      });
    },
    update(cache, result) {
      const addPost = result.data?.addPost;
      if (addPost) {
        cache.modify({
          fields: {
            feed(feedPage, { storeFieldName }) {
              if (
                storeFieldName.includes(FeedScope.FeedFollowing) ||
                storeFieldName.includes(FeedScope.FeedViral)
              ) {
                const newPostRef = cache.writeFragment({
                  fragment: PostCardFragment,
                  fragmentName: 'PostCardFragment',
                  data: addPost,
                });

                return updateFeedCache(feedPage, newPostRef);
              }

              return feedPage;
            },
          },
        });
        cache.modify({
          id: `User:${myId}`,
          fields: {
            posts(postsPage) {
              const newPostRef = cache.writeFragment({
                fragment: PostCardFragment,
                fragmentName: 'PostCardFragment',
                data: addPost,
              });

              return updateFeedCache(postsPage, newPostRef);
            },
          },
        });
      }
    },
  });

  const addPost = (input: RegularPostInput) => {
    return addPostMutation({
      variables: {
        input,
      },
    });
  };

  return [addPost, result] as const;
};
