import {
  RegularPostInput,
  RegularPostPreviewFragmentFragment,
  StreamPostCardFragmentFragment,
  StreamPostInput,
  TournamentPostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { Button } from '@synoptic/ui-kit/button/button.js';
import { FileInput } from '@synoptic/ui-kit/file-input/file-input.tsx';
import { Hint } from '@synoptic/ui-kit/hint/hint.tsx';
import { EyeRevealedIcon } from '@synoptic/ui-kit/icons/react/eye-revealed.tsx';
import { HelpCircleIcon } from '@synoptic/ui-kit/icons/react/help-circle.tsx';
import { ModalHeader, ModalTitle } from '@synoptic/ui-kit/modal/modal.tsx';
import { useIsTablet } from '@synoptic/ui-kit/responsive/hooks.ts';
import { SegmentedSlider } from '@synoptic/ui-kit/slider/segmented-slider.tsx';
import { useToast } from '@synoptic/ui-kit/toast/toast-provider.js';
import { EditorState } from 'lexical';
import React, { ReactNode, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { calculateReleaseAt } from '../../utils/calculate-release-at.ts';
import { PostCardPreview } from '../post-card/post-card-preview/post-card-preview.tsx';
import { useUserUploads } from '../s3/user/use-uploads.ts';
import { waitForUploads } from '../s3/wait-for-uploads.ts';
import { TextEditor } from '../text-editor/text-editor.tsx';
import { stateToText } from '../text-editor/utils/state-to-text.ts';
import { UploadPreview } from '../upload-preview/upload-preview.tsx';
import {
  formGroup,
  formGroupLabel,
  formGroupLabelText,
  formGroupOptionalLabel,
  formImgSection,
  hintIcon,
  newPostActions,
  newPostActionsRevealButton,
  newPostActionsRevealIcon,
  newPostActionsRow,
  newPostActionsSubmitButton,
  newPostContent,
  newPostContentContainer,
  newPostContentTextArea,
  newPostForm,
  newPostRevealedLabel,
  newPostRevealedLabelIcon,
} from './form.css.ts';
import {
  RevealTimeInputs,
  SetRevealTimeForm,
} from './set-reveal-time-form.tsx';
import { initialRevealTime } from '@/constants/initial-reveal-time.ts';

type FormInputs = {
  text: EditorState;
  reputationBet: number;
};

export type NewPostFormProps = {
  onSubmit: (input: RegularPostInput | StreamPostInput) => Promise<void>;
  label?: ReactNode;
  post?:
    | RegularPostPreviewFragmentFragment
    | StreamPostCardFragmentFragment
    | TournamentPostCardFragmentFragment;
  withReputationBet?: boolean;
  titleText?: string;
  withHidden?: boolean;
};

export const NewPostForm: React.FC<NewPostFormProps> = ({
  onSubmit,
  label,
  post,
  withReputationBet = true,
  titleText = 'Create new post',
  withHidden = true,
}) => {
  const isTablet = useIsTablet();
  const { handleSubmit, formState, control } = useForm<FormInputs>({
    defaultValues: { reputationBet: 0 },
  });
  const toast = useToast();
  const {
    uploadFileList,
    uploads,
    onRemove: onRemoveUpload,
  } = useUserUploads({ limit: 4, mode: 'append' });

  const [isDragOver, setIsDragOver] = useState(false);
  const [revealFormShown, setRevealFormShown] = useState(false);
  const [revealTime, setRevealTime] =
    useState<RevealTimeInputs>(initialRevealTime);

  const formRef = useRef<HTMLFormElement>(null);

  const submit: SubmitHandler<FormInputs> = async (data) => {
    let media;
    try {
      media = await waitForUploads(uploads);
    } catch {
      toast.error({
        title: 'Some of your media failed to upload.',
      });
      return;
    }

    return onSubmit({
      text: stateToText(data.text),
      json: JSON.stringify(data.text.toJSON()),
      reputationBet: withReputationBet ? data.reputationBet : undefined,
      media,
      quotePostId: post?.id,
      releaseAt:
        Object.values(revealTime).some((value) => Number(value) > 0) ||
        Object.values(revealTime).some((value) => value === '0')
          ? calculateReleaseAt(revealTime)
          : undefined,
    });
  };

  return (
    <>
      <form
        className={newPostForm}
        style={{ display: revealFormShown ? 'none' : 'flex' }}
        ref={formRef}
        onSubmit={handleSubmit(submit)}
        data-drag-over={isDragOver ? '' : undefined}
        onKeyDownCapture={(e) => {
          if (formRef.current && e.key === 'Enter' && e.metaKey) {
            e.stopPropagation();

            formRef.current.requestSubmit();
          }
        }}
        onDragOver={(e) => {
          if (e.dataTransfer.items.length > 0) {
            e.preventDefault();
            setIsDragOver(true);
          }
        }}
        onDragLeave={() => setIsDragOver(false)}
        onDrop={(e) => {
          if (e.dataTransfer.files.length > 0) {
            e.preventDefault();
            uploadFileList(e.dataTransfer.files);
            setIsDragOver(false);
          }
        }}
        onPaste={(e) => {
          if (e.clipboardData.files.length > 0) {
            e.preventDefault();
            uploadFileList(e.clipboardData.files);
          }
        }}
      >
        <ModalHeader>
          <ModalTitle>{titleText}</ModalTitle>
        </ModalHeader>
        <div className={newPostContentContainer}>
          <div className={newPostContent}>
            {(revealTime.hours || revealTime.minutes || revealTime.seconds) && (
              <div className={newPostRevealedLabel}>
                <EyeRevealedIcon className={newPostRevealedLabelIcon} />
                Will be revealed in {revealTime.hours || 0} hour{' '}
                {revealTime.minutes || 0} mins {revealTime.seconds || 0} secs
              </div>
            )}
            <Controller
              name="text"
              control={control}
              rules={{
                validate: (v) => (v ? stateToText(v).trim().length > 0 : false),
              }}
              render={({ field }) => (
                <TextEditor
                  namespace="new-post"
                  editorRef={field.ref}
                  onBlur={field.onBlur}
                  theme="textarea"
                  autoFocus
                  editable
                  placeholder="What are your thoughts?"
                  onChange={(state) => {
                    field.onChange(state);
                  }}
                  contentEditableClassName={newPostContentTextArea}
                />
              )}
            />

            {uploads.length > 0 ? (
              <div
                data-testid={'new-post-img-section'}
                className={formImgSection}
              >
                {uploads.map(({ key, file, progress, state }) => (
                  <UploadPreview
                    key={key}
                    file={file}
                    state={state}
                    progress={progress}
                    onRemove={() => onRemoveUpload(key)}
                    compact
                  />
                ))}
              </div>
            ) : null}

            {withReputationBet ? (
              <div className={formGroup}>
                <div className={formGroupLabel}>
                  <span className={formGroupLabelText}>
                    Choose reputation bet
                  </span>
                  <Hint trigger={<HelpCircleIcon className={hintIcon} />}>
                    Reputation bet sets how much reputation user is willing to
                    risk on this post.
                  </Hint>
                </div>
                <div className={formGroupOptionalLabel}>optional field</div>
                <Controller
                  name="reputationBet"
                  control={control}
                  render={({ field }) => (
                    <SegmentedSlider
                      min={0}
                      defaultValue={[field.value]}
                      onValueCommit={([v]) => field.onChange(v)}
                      disabled={field.disabled}
                      step={0.1}
                    />
                  )}
                />
              </div>
            ) : null}

            {label ? <div>{label}</div> : null}

            {post && <PostCardPreview post={post} clickableAuthor={false} />}
          </div>
        </div>

        <div className={newPostActions}>
          <div className={newPostActionsRow}>
            <FileInput
              onChange={(e) => {
                if (e.target.files) {
                  uploadFileList(e.target.files);
                  e.target.value = '';
                }
              }}
              accept="image/*,video/*"
              multiple
            >
              Attach media
            </FileInput>

            {withHidden && (
              <Button
                type="button"
                size={'small'}
                onClick={() => setRevealFormShown(true)}
                variant="ghost"
                startIcon={
                  <EyeRevealedIcon className={newPostActionsRevealIcon} />
                }
                className={newPostActionsRevealButton}
              >
                {isTablet && 'Set reveal time'}
              </Button>
            )}
          </div>

          <Button
            disabled={!formState.isValid}
            type="submit"
            size={'large'}
            className={newPostActionsSubmitButton}
            loading={formState.isSubmitting}
          >
            Post
          </Button>
        </div>
      </form>

      {revealFormShown ? (
        <SetRevealTimeForm
          values={{
            ...revealTime,
            seconds: !revealTime.seconds ? '10' : revealTime.seconds,
          }}
          onSubmit={(v) => {
            setRevealTime({
              hours: v.hours || '0',
              minutes: v.minutes || '0',
              seconds: v.seconds || '0',
            });
            setRevealFormShown(false);
          }}
          onCancel={() => setRevealFormShown(false)}
        />
      ) : null}
    </>
  );
};
