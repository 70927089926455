import { LabelTag } from '@/components/label-tag/label-tag.tsx';
import { FC } from 'react';
import { clsx } from 'clsx';
import {
  percentageLabel,
  percentageLabelVariants,
} from '@/components/tournament-post-card/percentage-label.css.ts';

export const PercentageLabel: FC<{ value: number }> = ({ value }) => {
  const labelVariant = value < 40 ? 'low' : value > 59 ? 'high' : 'medium';
  return (
    <LabelTag
      className={clsx(percentageLabel, percentageLabelVariants[labelVariant])}
    >
      {value}%
    </LabelTag>
  );
};
