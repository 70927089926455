import { FieldType } from '@/__generated__/graphql';
import { PostCardMedia } from '../post-card/post-card-media/post-card-media';
import { postCardBody } from '../post-card/post-card.css';
import { TextEditor } from '../text-editor/text-editor';
import { PredictionTournamentPostField } from '@/components/tournament-post-card/prediction-tournament-post-field.tsx';

export const TournamentPostField = ({
  name,
  type,
  value,
  authorId,
  index,
}: {
  name: string;
  type: FieldType;
  value?: string;
  authorId: string;
  index?: number;
}) => {
  switch (type) {
    case FieldType.RichText:
      return (
        <TextEditor
          namespace={`tournament-post:${name}`}
          className={postCardBody}
          initialState={value}
          editable={false}
          withShowMore
          charLimit={330}
        />
      );
    case FieldType.Percentage:
      if (index === undefined || !value) return null;

      return (
        <PredictionTournamentPostField
          name={name}
          value={value}
          index={index + 1}
        />
      );
    case FieldType.Media:
      return (
        <PostCardMedia
          media={
            value
              ? (JSON.parse(value) as { key: string; type: string }[])
              : undefined
          }
          authorId={authorId}
        />
      );
    default:
      return null;
  }
};
