import {
  Action as RadixToastAction,
  type ToastActionProps,
} from '@radix-ui/react-toast';
import { ElementRef, forwardRef } from 'react';
import { Button, ButtonProps } from '../button/button';
import { toastAction } from './toast-action.css';

export const ToastAction = forwardRef<
  ElementRef<typeof RadixToastAction>,
  ButtonProps & { altText: ToastActionProps['altText'] }
>(function ToastAction({ children, altText, ...props }, ref) {
  return (
    <RadixToastAction
      className={toastAction}
      ref={ref}
      altText={altText}
      asChild
    >
      <Button size="small" inversed variant="ghost" {...props}>
        {children}
      </Button>
    </RadixToastAction>
  );
});
export type ToastActionElement = React.ReactElement<typeof ToastAction>;
