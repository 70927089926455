import { TextEditor } from '../text-editor/text-editor';
import {
  numericBadge,
  predictionFieldBody,
  predictionFieldContainer,
} from '@/components/tournament-post-card/prediction-tournament-post-field.css.ts';
import { PercentageLabel } from '@/components/tournament-post-card/percentage-label.tsx';
import { NumericBadge } from '@/components/numeric-badge/numeric-badge.tsx';

export const PredictionTournamentPostField = ({
  name,
  value,
  index,
}: {
  name: string;
  value: string;
  index: number;
}) => {
  return (
    <div className={predictionFieldContainer}>
      <NumericBadge className={numericBadge}>{index}</NumericBadge>
      <TextEditor
        namespace={`tournament-post:${name}`}
        className={predictionFieldBody}
        initialState={name}
        editable={false}
        charLimit={330}
        withShowMore
      />
      <PercentageLabel value={+value} />
    </div>
  );
};
