import { PostCardFragmentFragment } from '@/__generated__/graphql.ts';
import { Link } from '@/routes-utils/navigation.tsx';
import { UserBadge } from '../user-badge/user-badge.tsx';
import { postAuthorContainer } from './post-author.css.ts';

export const PostAuthor: React.FC<{
  author: PostCardFragmentFragment['author'];
  clickableAuthor?: boolean;
}> = ({ author, clickableAuthor = true }) => {
  return clickableAuthor ? (
    <Link
      to={`/${author.username}`}
      className={postAuthorContainer}
      onClick={(e) => e.stopPropagation()}
      prefetch="intent"
      data-following={author.following}
    >
      <UserBadge id={author.id} />
    </Link>
  ) : (
    <div className={postAuthorContainer} data-following={author.following}>
      <UserBadge id={author.id} underlineTitleOnHover={false} />
    </div>
  );
};
