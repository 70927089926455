import { EmptyState } from '@synoptic/ui-kit/empty-state/empty-state.js';
import { TrashIcon } from '@synoptic/ui-kit/icons/react/trash.js';
import { memo } from 'react';
import {
  BaseStreamPostCardFragmentFragment,
  BaseTournamentPostCardFragmentFragment,
  RegularPostPreviewFragmentFragment,
} from '@/__generated__/graphql.ts';
import { formatDateWithMs } from '@/date-utils/format-date-with-ms.ts';
import { TextEditor } from '../../text-editor/text-editor.tsx';
import { PostAuthor } from '../post-author.tsx';

import { clsx } from 'clsx';
import { formatDate } from '@/date-utils/format-date.ts';
import { TournamentPostCardContent } from '../../tournament-post-card/tournament-post-card-content.tsx';
import { PostCardHiddenLabel } from '../post-card-hidden-label.tsx';
import { PostCardMedia } from '../post-card-media/post-card-media.tsx';
import {
  postCardBody,
  postCardContainer,
  postCardContent,
  postCardDate,
  postCardDeletedPlaceholder,
  postCardHeader,
  postCardHeaderContent,
} from '../post-card.css.ts';
import {
  previewPostCardContainer,
  previewPostCardContainerWithHover,
} from './post-card-preview.css.ts';

type PostProps = {
  post:
    | RegularPostPreviewFragmentFragment
    | BaseStreamPostCardFragmentFragment
    | BaseTournamentPostCardFragmentFragment;
  clickableAuthor?: boolean;
  hoverable?: boolean;
};
export const PostCardPreview = memo<PostProps>(function PostCardPreview({
  post,
  clickableAuthor = true,
  hoverable = false,
}) {
  const isDeleted = Boolean(post.deletedAt);

  return (
    <div
      className={clsx(
        postCardContainer,
        previewPostCardContainer,
        hoverable && previewPostCardContainerWithHover,
      )}
    >
      {post.__typename === 'RegularPost' && post.releaseAt && (
        <PostCardHiddenLabel />
      )}

      <div className={postCardHeader}>
        <div className={postCardHeaderContent}>
          <PostAuthor
            author={{ ...post.author, following: false }}
            clickableAuthor={clickableAuthor}
          />
        </div>
      </div>

      <div className={postCardContent}>
        {isDeleted ? (
          <EmptyState
            illustration={<TrashIcon />}
            heading="The post was deleted"
            className={postCardDeletedPlaceholder}
          />
        ) : post.__typename === 'TournamentPost' ? (
          <TournamentPostCardContent post={post} />
        ) : (
          <>
            <TextEditor
              namespace={`post:${post.id}`}
              className={postCardBody}
              initialState={post.json || post.text}
              editable={false}
            />

            <PostCardMedia media={post.media} authorId={post.author.id} />
          </>
        )}

        <time
          title={formatDateWithMs(new Date(post.createdAt))}
          dateTime={post.createdAt}
          className={postCardDate}
        >
          {formatDate(new Date(post.createdAt))}
        </time>
      </div>
    </div>
  );
});
