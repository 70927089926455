import { EyeRevealedIcon } from '@synoptic/ui-kit/icons/react/eye-revealed.tsx';
import {
  postCardHiddenLabelContainer,
  postCardHiddenLabelIcon,
  postCardHiddenLabelText,
} from './post-card.css.ts';

export const PostCardHiddenLabel = () => {
  return (
    <div className={postCardHiddenLabelContainer}>
      <EyeRevealedIcon className={postCardHiddenLabelIcon} />
      <span className={postCardHiddenLabelText}>Timed reveal post</span>
    </div>
  );
};
