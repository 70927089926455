import {
  Range,
  Root,
  SliderProps as SliderRootProps,
  Thumb,
  Track,
} from '@radix-ui/react-slider';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { useControllableState } from '../utils/use-controllable-state';
import {
  slider,
  sliderContainer,
  sliderLabel,
  sliderLabelTextContainer,
  sliderRange,
  sliderThumb,
  sliderTrack,
  valueLabel,
  valueLabelContainer,
  valueLabelTriangle,
} from './segmented-slider.css.ts';
import { space } from '../tokens.ts';

export type SliderProps = {
  withLabels?: boolean;
  withSegmentLabels?: boolean;
  suffix?: string;
  className?: string;

  defaultValue?: SliderRootProps['defaultValue'];
  value?: SliderRootProps['value'];
  onValueChange?: SliderRootProps['onValueChange'];
  onValueCommit?: SliderRootProps['onValueCommit'];
  min?: SliderRootProps['min'];
  max?: SliderRootProps['max'];
  step?: SliderRootProps['step'];
  disabled?: boolean;
  segmentsCount?: number;
};

export const SegmentedSlider = forwardRef<HTMLDivElement, SliderProps>(
  function SliderComponent(
    {
      className,
      value,
      defaultValue,
      onValueChange,
      onValueCommit,
      min = 0,
      max = 3,
      withLabels = true,
      withSegmentLabels = true,
      step,
      disabled,
      suffix,
      segmentsCount = 3,
    },
    ref,
  ) {
    const [sliderValue, setSliderValue] = useControllableState({
      value,
      defaultValue,
      onChange: onValueChange,
    });

    return (
      <div ref={ref} className={clsx(sliderContainer, className)}>
        <Root
          className={slider}
          value={sliderValue}
          onValueChange={setSliderValue}
          onValueCommit={onValueCommit}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
        >
          <Track className={sliderTrack} />
          {Array.from({ length: segmentsCount - 1 }).map((_, index) => (
            <Track
              key={index}
              className={sliderTrack}
              style={{ marginLeft: space.s4 }}
            />
          ))}
          <Range className={sliderRange} />
          <Thumb className={sliderThumb}>
            {sliderValue && withLabels ? (
              <div className={valueLabelContainer} aria-hidden="true">
                <span className={valueLabel}>{sliderValue[0].toFixed(1)}</span>
                <div className={valueLabelTriangle} />
              </div>
            ) : null}
          </Thumb>
        </Root>

        {withLabels ? (
          <div className={sliderLabel}>
            <span aria-hidden="true">
              {min}
              {suffix}
            </span>
            <span aria-hidden="true">
              {max}
              {suffix}
            </span>
            <div className={sliderLabelTextContainer}>
              {withSegmentLabels ? (
                <>
                  <span>Low</span>
                  <span>Medium</span>
                  <span>High</span>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  },
);
