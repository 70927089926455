import { gql } from '@/__generated__';

export const PostCardFragment = gql(`
  fragment PostCardFragment on RegularPost {
    ...BasePostFragment
    quotedPost {
      ... on RegularPost {
        ...RegularPostPreviewFragment
      }
      ... on StreamPost {
        ...StreamPostCardFragment
      }
      ... on TournamentPost {
        ...TournamentPostCardFragment
      }
      ... on RePost {
        id
        originalPost {
          ...RegularPostPreviewFragment
        }
      }
    }
  }
`);
