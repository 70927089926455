import { BaseTournamentPostCardFragmentFragment } from '@/__generated__/graphql';
import { TournamentPostField } from '@/components/tournament-post-card/tournament-post-fields.tsx';

export const TournamentPostCardContent = ({
  post,
}: {
  post: BaseTournamentPostCardFragmentFragment;
}) => {
  const fieldValues: { [name: string]: string } = {};

  for (const field of post.fields) {
    fieldValues[field.name] = field.value;
  }

  return (
    <>
      {post.tournament.fields.map(({ name, type }, index) => (
        <TournamentPostField
          key={name}
          name={name}
          type={type}
          value={fieldValues[name]}
          authorId={post.author.id}
          index={index}
        />
      ))}
    </>
  );
};
